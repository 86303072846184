import React from "react"
import { StaticQuery, graphql } from "gatsby"

class LastUpdated extends React.Component {

  render () {

    const options = { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', timeZoneName: 'short'};
    const mTime = new Date(this.props.data.allSheetMessiMatchHistory.edges[0].node.timestamp.replace(/-/g,"/"));
    const rTime = new Date(this.props.data.allSheetRonaldoMatchHistory.edges[0].node.timestamp.replace(/-/g,"/"));

    const timestamp = (a, b) => {
      if(a < b) {
        return(b.toLocaleDateString("en-US", options));
      } else {
        return(a.toLocaleDateString("en-US", options));
      }
    }

    return (

      <div className="last-updated fixed top-0 left-0 z-40 md:top-auto md:bottom-0 w-full md:w-auto p-2 bg-noir-lighten5 text-center text-xs text-whiteo-30">
        <p>
          Updated&nbsp;
          {timestamp(mTime, rTime)}
        </p>
      </div>

    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query MyQuery {
        allSheetMessiMatchHistory {
          edges {
            node {
              timestamp
            }
          }
        }
        allSheetRonaldoMatchHistory {
          edges {
            node {
              timestamp
            }
          }
        }
      }
    `}
    render={(data) => (
      <LastUpdated data={data} {...props} />
    )}
  />
)


