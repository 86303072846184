import React from "react"
import Nav from "./Nav"

import "./MenuBar.css"

class MenuBar extends React.Component {
  render() {
    return (

      <div className="menu-bar">

        <Nav type="nav--header" navHoverReady={this.props.navHoverReady} />

      </div>

    )
  }
}

export default MenuBar
