/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import favicon from '../images/icon-48x48.png';

import ronaldoxs from "../images/ronaldo-xs.png"

function SEO({ title, description, canonicalPath, image, twitterCreator }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            siteName
            image
            twitterCreator
            twitterSite
          }
        }
      }
    `
  )

  const siteUrl = site.siteMetadata.siteUrl

  const seo = {
    title: title || site.siteMetadata.title,
    description: description || site.siteMetadata.description,
    url: `${siteUrl}${canonicalPath || ``}`,
    image: `${siteUrl}${image || site.siteMetadata.image}`,
    siteName: site.siteMetadata.siteName,
    canonicalPath: canonicalPath || '',
    twitterCreator: twitterCreator || site.siteMetadata.twitterCreator,
    twitterSite: site.siteMetadata.twitterSite
  };

  const schemaOrg = {
    "@context": "https://schema.org",
    "@type": "ResearchProject",
    "@id": "https://www.messivsronaldo.app",
    "description": "The most complete and up-to-date statistical comparison of Messi vs Ronaldo.",
    "name": "Messi vs Ronaldo",
    "alternateName": "MessivsRonaldo.app",
    "url": "https://www.messivsronaldo.app",
    "logo": "https://www.messivsronaldo.app/feature.jpg",
    "sameAs": [
      "https://www.facebook.com/messivsronaldoapp/",
      "https://twitter.com/mvsrapp",
      "https://www.instagram.com/messivsronaldo.app/",
      "https://www.messivsronaldo.app/"
    ]
  }


  return (
    <Helmet
      title={seo.title}
      titleTemplate={`%s`}
    >
      <html lang="en" itemScope itemType={seo.canonicalPath === '/' ? 'https://schema.org/FAQPage' : ''} />
      <link rel="canonical" href={`${siteUrl}${seo.canonicalPath}`}/>
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
      />
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />

      <meta property='og:title' content={seo.title} />
      <meta property='og:url' content={seo.url} />
      <meta property='og:description' content={seo.description} />
      <meta property="og:image" content={seo.image} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="en" />
      <meta property="og:site_name" content={seo.siteName} />

      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:image' content={seo.image} />
      {/* <meta name='twitter:creator' content={seo.twitterCreator} /> */}
      {/* <meta name="twitter:site" content={seo.twitterSite} /> */}

      <link rel="shortcut icon" href={favicon} type="image/png" />

      <link rel="preload" as="image" href={ronaldoxs} />

      {seo.canonicalPath === '/' ? (
        <script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
      ) : '' }

      {/* <script type="text/javascript" src="https://ads.vidoomy.com/messivsronaldo_18711.js" async></script> */}
      
      {/* <script src="https://emea.hhkld.com/tag/load-107096.js?page_url=https%3A%2F%2Fwww.messivsronaldo.app%2F" async charset="UTF-8"></script> */}
      <script async src="//www.ezojs.com/ezoic/sa.min.js"></script>

    </Helmet>
  )
}

export default SEO
