import React from "react"

import "./Underlay.css"

// import "./menu-bar.css"

class Underlay extends React.Component {
  render() {
    return (

      <div onClick={this.props.handler} onKeyDown={this.props.handler} className={`${this.props.state} underlay`} role="button" tabIndex="0">

      </div>

    )
  }
}

export default Underlay
