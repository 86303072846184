import { Link } from "gatsby"
import React from "react"
import { globalHistory } from "@reach/router"
import MenuBar from "./MenuBar"

import Icon from '../Utilities/Icon'

import messixs from "../../images/messi-xs.png"
import messism from "../../images/messi-sm.png"
import messilg from "../../images/messi.png"
import ronaldoxs from "../../images/ronaldo-xs.png"
import ronaldosm from "../../images/ronaldo-sm.png"
import ronaldolg from "../../images/ronaldo.png"

import { StaticQuery, graphql } from "gatsby"
//import Img from "gatsby-image"

import "./Header.css"


class Header extends React.Component {

  // constructor(props) {
  //   super(props)
  //   this.activateNavDropdowns = this.activateNavDropdowns.bind(this)
  // }

  // state = {
  //   navHoverReady: false,
  // };

  // activateNavDropdowns = () => {
  //   this.setState({navHoverReady: true });
  // }

  render() {
    const { children } = this.props;
    const path = globalHistory.location.pathname;
    return (
      <StaticQuery
        query={graphql`
        query {
          ronaldo: file(relativePath: { eq: "ronaldo.png" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          messi: file(relativePath: { eq: "messi.png" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        `}
        render={data => (
          <header className={`overflow-hidden`}>

            <MenuBar />
            <button
              id="MainMenuToggle"
              aria-label="Main Menu Toggle"
              onClick={this.props.handler}
              className={`${this.props.state} nav-toggle fixed right-0 bottom-0 bg-highlight text-center flex items-center py-2 px-4 md:bottom-auto md:top-0 focus:outline-none focus:bg-highlight-lighten10 text-noir`}
            >
              { this.props.state === "active" ? <Icon iconName="icon-times" class="block mr-2" /> : <Icon iconName="icon-bars" class="block mr-2" /> }
              <span className="text-xs uppercase">Menu</span>
            </button>

            <div className={`hero ${path === '' ? 'hero--home xl:mb-28 2xl:mb-32' : ''} mx-auto w-full flex relative mt-16 md:mt-10 lg:mt-16 xl:mt-20 mb-12 sm:mb-16`} onMouseOver={this.activateNavDropdowns} onFocus={this.activateNavDropdowns} role="banner">
              {/* <img src={messi} alt="Messi" className="hero__messi absolute bottom-0 left-0" /> */}
              {/* <img src={ronaldoXs} alt="Ronaldo" className="hero__ronaldo absolute bottom-0 right-0" /> */}
              <picture className="hero__messi absolute bottom-0 left-0">
                <source srcSet={messilg} media="(min-width: 1024px)" />
                  <source srcSet={messism} media="(min-width: 600px)" />
                  <img src={messixs} alt="" />
              </picture>
              <picture className="hero__ronaldo absolute bottom-0 right-0">
                <source srcSet={ronaldolg} media="(min-width: 1024px)" />
                  <source srcSet={ronaldosm} media="(min-width: 600px)" />
                  <img src={ronaldoxs} alt="" />
              </picture>
              <Link className="logo inline-block mx-auto font-black text-3xl leading-none uppercase" to="/" >
                <span className="logo__messi-vs block relative z-10">Messi Vs </span>
                <span className="logo__ronaldo block relative z-10">Ronaldo</span>
                <span className="logo__app block relative z-10 font-semibold mt-1 normal-case text-right opacity-25">.app</span>
              </Link>
            </div>

            {/* <div className="relative z-10 text-center mb-4 flex items-baseline justify-center">
              <a className="text-highlight hover:text-highlight-lighten15 underline" href="https://tpjr.us/mvsrapp" target="_blank" rel="noreferrer">Support this site</a>
            </div> */}

          </header>
        )}
      />
    )
  }
}


export default Header
