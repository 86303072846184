import { Link } from "gatsby"
import React from "react"
import { StaticQuery, graphql } from "gatsby"
//import { globalHistory } from "@reach/router"
import Icon from '../Utilities/Icon'
import NavLinkDropdown from './NavLinkDropdown'
import { AnchorLink } from "gatsby-plugin-anchor-links";

import "./Nav.css"

class Nav extends React.Component {

  // constructor(props) {
  //   super(props)
  //   this.activateNavDropdowns = this.activateNavDropdowns.bind(this)
  // }

  // state = {
  //   hoverReady: false
  // };

  // activateNavDropdowns() { this.setState({hoverReady: true }); }

  render () {

    const mobScrollContainer = (children) => {
      if(this.props.type === "nav--slideout") {
        return (
          <div className="scroll-container">{children}</div>
        )
      } else {
        return(
          {...children}
        )
      }
    }
  
    return (

      <nav className={`nav ${this.props.type} ${this.props.state}`}>
        
        {mobScrollContainer(
          <ul>

            {/* <li><Link to="/" activeClassName="active">All Time <span className="sr-only sm:not-sr-only">Stats</span></Link></li> */}

            {/* <li><a rel="noreferrer" target="_blank" href="https://store.messivsronaldo.app/">STORE</a></li> */}

            <NavLinkDropdown
              linkText={<span>All Time <span className="sr-only sm:not-sr-only">Stats</span></span>}
              linkTo={`/`}
              parentPath={`/all-time-stats/`}
              subnavClass='all-time-sub'
            />

            <NavLinkDropdown
              linkText={<span><span className="sr-only sm:not-sr-only">Club</span> Seasons</span>}
              linkTo={`/club-stats/`}
              highlightFirstChildOnParent={true}
              subnavClass='seasons-sub'
            />

            <NavLinkDropdown
              linkText={<span>Detailed <span className="sr-only sm:not-sr-only">Stats</span></span>}
              linkTo={`/detailed-stats/`}
              subnavClass='detailed-sub'
            />

            <NavLinkDropdown
              linkText={<span>Match Histories</span>}
              linkTo={`/match-histories/`}
              subnavClass='matches-sub'
            />

            <li><Link to="/records/" activeClassName="active">Records</Link></li>

            <NavLinkDropdown
              linkText={<span>Honours</span>}
              linkTo={`/honours-and-achievements/`}
              highlightFirstChildOnParent={true}
              subnavClass='honours-sub'
            />

            <NavLinkDropdown
              linkText="International"
              linkTo={`/international-stats/`}
              highlightFirstChildOnParent={true}
              subnavClass='int-sub'
            />

            <NavLinkDropdown
              linkText="Calendar Year"
              linkTo={`/calendar-year-stats/`}
              highlightFirstChildOnParent={true}
              subnavClass='cal-year-sub'
            />

            <li><Link to="/articles/" activeClassName="active">Articles</Link></li>

            {/* <NavLinkDropdown
              linkText="Articles"
              linkTo={`/articles/`}
              highlightFirstChildOnParent={true}
              subnavClass='articles-sub'
            /> */}

            {/* <li><Link to="/results" activeClassName="active">Results</Link></li>
            <li><Link to="/articles" activeClassName="active">Articles</Link></li>
            <li><Link to="/contact" activeClassName="active">Contact</Link></li> */}

            <li><AnchorLink to="/#whos-the-best" title="Who's the best?" /></li>
            <li><AnchorLink to="/vote-and-discuss/" title="Vote &amp; Discuss" /></li>

            <NavLinkDropdown
              linkText="Help"
              linkTo={`/info/`}
              highlightFirstChildOnParent={true}
              subnavClass='info-sub'
            />

            <li><a className="sponsored" rel="noreferrer" target="_blank" href="https://www.sure.bet/non-gamstop-casinos-uk/">Casinos Not On GamStop</a></li>
            <li><a className="sponsored" rel="noreferrer" target="_blank" href="https://uk.nonstopcasino.org/non-gamstop-casinos/">UK NonStop Casino</a></li>
            <li><a className="sponsored" rel="noreferrer" target="_blank" href="https://www.nongamstopbets.com/uk/">NonGamStopBets Bookies</a></li>
            <li><a className="sponsored" rel="noreferrer" target="_blank" href="https://nongamstopcasinos.org/betting-sites-not-on-gamstop/">NonGamStopCasinos Betting Sites</a></li>

            <li><a className="social" rel="noreferrer" target="_blank" href="https://twitter.com/mvsrapp"><Icon iconName="icon-twitter" class="" /> <span className="sr-only">Twitter</span></a></li>
            <li><a className="social" rel="noreferrer" target="_blank" href="https://www.instagram.com/messivsronaldo.app/"><Icon iconName="icon-instagram" class="" /> <span className="sr-only">Instagram</span></a></li>
            <li><a className="social" rel="noreferrer" target="_blank" href="https://www.facebook.com/messivsronaldoapp/"><Icon iconName="icon-facebook" class="" /> <span className="sr-only">Facebook</span></a></li>
          </ul>
        )}
      </nav>

    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query SiteQuery {
        allSitePage {
          edges {
            node {
              path
              context {
                slug
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <Nav site={data} {...props} />
    )}
  />
)

