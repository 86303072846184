/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from "./AppShell/Header"
import Nav from "./AppShell/Nav"
import Underlay from "./AppShell/Underlay"
import "./layout.css"


class Layout extends React.Component  {
  
  constructor(props) {
    super(props)
    this.toggleMenuActive = this.toggleMenuActive.bind(this)
    this.removeMenuActive = this.removeMenuActive.bind(this)
  }

  state = {
    menuIsActive: false
  };

  toggleMenuActive() { this.setState(state => ({ menuIsActive: !state.menuIsActive })); }
  removeMenuActive() { this.setState(({ menuIsActive: false })); }

  render() {
    const { children, location } = this.props;
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
            allSitePage {
              edges {
                node {
                  id
                  path
                  context {
                    slug
                  }
                  isCreatedByStatefulCreatePages
                }
              }
            }
          }
        `}
        render={data => (
          <>

              <Header handler={this.toggleMenuActive} state={this.state.menuIsActive ? 'active' : ''} />
  
              <div id="ezoic-pub-ad-placeholder-102"> </div>

              <div>

                <main>{children}</main>

                <div id="ezoic-pub-ad-placeholder-144"> </div>

                <footer>
                  © {new Date().getFullYear()} {data.site.siteMetadata.title} | Illustrations by <a className={`hover:text-gray-600 underline`} rel="noopener noreferrer" target="_blank" href="https://dlanid.com/">Denis Davydov</a> | <a className={`hover:text-gray-600 underline`} rel="noreferrer" target="_blank" href="https://www.topscorers.club/">Top Scorers Club</a> | <a className={`hover:text-gray-600 underline`} href="mailto:hi@messivsronaldo.app">Contact</a>
                </footer>

              </div>

              <Nav state={this.state.menuIsActive ? 'active' : ''} type="nav--slideout" />

              <Underlay handler={this.removeMenuActive} state={this.state.menuIsActive ? 'active' : ''} />

            </>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
